.TitleLogo {
  letter-spacing: normal;
  color: #fff;
  color: var(--white);
  font-size: 38px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
}

.Title-Subhead {
  letter-spacing: normal;
  color: #fff;
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
}

h1 {
  letter-spacing: normal;
  color: #333;
  color: var(--darkgrey-1);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
}

h2 {
  letter-spacing: normal;
  color: #989898;
  color: var(--mediumgrey-1);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
}

h3 {
  letter-spacing: normal;
  color: #333;
  color: var(--darkgrey-1);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
}

body {
  letter-spacing: normal;
  color: #333;
  color: var(--darkgrey-1);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
}

h4 {
  letter-spacing: normal;
  color: #333;
  color: var(--darkgrey-1);
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
}

.caption1 {
  letter-spacing: normal;
  color: #0072ce;
  color: var(--blue);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
}

h6 {
  letter-spacing: normal;
  color: #fff;
  color: var(--white);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
}

.caption2 {
  letter-spacing: normal;
  color: #60b8ff;
  color: var(--lightblue);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
}

/*# sourceMappingURL=index.3bd7fa0c.css.map */
